/* eslint-disable no-undef */
export const emailResponsablePlataforma = async (plataforma, data) => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/responsables/plataforma/${plataforma}/notificar`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Credentials': true
        },
        body: JSON.stringify(data)
    });
    return response;
};

export const traeResponsablePlataforma = async (plataforma) => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/responsables/plataforma/${plataforma}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            Accept: 'application/json',
            'Access-Control-Allow-Credentials': true
        }
    });
    return response;
};