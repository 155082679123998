export const queryServer = async (callbackFunc, payload, rejectWithValue) => {
    try {
        const response = await callbackFunc(payload);
        if (! response.ok) {
            throw new Error(`Received error ${response.status}`);
        }
        return response;
    } catch (e) {
        return rejectWithValue(e.message);
    }
};

export const plataformas = [
    '',
    'Alcalá de Henares',
    'Atocha',
    'Estrecho',
    'Fuenlabrada',
    'Sol',
    'Pan Bendito',
    'Parla',
    'Pº de Extremadura',
    'Puertollano',
    'Garelli',
];

export const escenarios = [
    'Suceso en la vida de la persona vulnerable (fuera de nuestras actividades)',
    'Suceso entre iguales',
    'Suceso en nuestras actividades (vulneración Código de Conducta)'
];

export const writeSentenceFromArray = (list) => {
    const sentence = list.length === 0 ? '' :
        list.length === 1 ? list[0] :
            list.length === 2 ? list.join(' y ') :
                list.slice(0, -1).join(', ') + ' y ' + list[list.length - 1];
    return sentence;
};

export const writeListFromArray = (list) => {
    const sentence = list.length === 0 ? '' :
        list.length === 1 ? list[0] :
            list.join(', ');
    return sentence;
};

//export const SERVER_PORT = 'https://54.74.26.246:443'; //'https://54.74.26.246:443';
//export const SERVER_PORT = 'https://mongo.pinardi.com:443';