import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { STATUS, editExpediente } from './expedienteSlice';
import { selectUser } from '../auth/authSlice';
import Actuaciones from './actuacion/Actuaciones';
import Spinner from '../../components/Spinner';
import { escenarios, plataformas } from '../../app/utils.js';
import {traeResponsablePlataforma} from '../usuario/responsableAPI.js';

// eslint-disable-next-line react/prop-types
export default function EditFormExpediente ({num, onClickHandler}) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { expediente, status, error } = useSelector((state) => state.expediente);
    const user = useSelector(selectUser);

    useEffect(()=>{
        if (num && status === STATUS.SAVED && !(expediente.abierto)) {
            navigate(`/expediente/${expediente.numero}/ver`);
        }
    }, [num, status, expediente.abierto]);

    async function getResponsable(plataforma) {
        try {
            const response = await traeResponsablePlataforma(plataforma);
            if (response.ok) {
                const responsable = await response.json();
                return responsable.responsable;
            } else {
                const e = await response.text();
                throw new Error(e);
            }
        } catch (error) {
            alert('No se pudo encontrar al responsable de la plataforma.');
            return 'Aurora Martín';
        }
    }

    async function onChangeExpedienteHandler({target}) {
        const userInput = target.value;
        let field = target.id;
        dispatch(editExpediente({
            key: field,
            value: userInput
        }));
        if (field == 'plataforma') {
            field = 'responsable';
            const value = await getResponsable(userInput);
            dispatch(editExpediente({
                key: field,
                value: value
            }));
        }
    }

    return (
        <> 
            { status === STATUS.SAVING 
                ? <>
                    <p>Guardando...</p>
                    <Spinner/>
                </>
                : ''
            }
            { error === STATUS.COULDNT_SAVE
                ? <div className='title'>
                    <h2>ERROR: No se ha podido guardar.</h2>
                </div>
                : ''
            }
            {num 
                ? <div className="title">
                    <h2>Expediente nº {num}</h2>
                    <h3>Estado: Abierto</h3>
                    <h3>Tipo de escenario: {escenarios[parseInt(expediente.escenario)-1]}</h3>
                </div>
                : <div className="title">
                    <label htmlFor="numero">Expediente nº:</label>
                    <input type="text" name="numero" id="numero"
                        value={expediente.numero || ''}
                        onChange={onChangeExpedienteHandler}/>
                </div>
            }
            { status === STATUS.UNSAVED
                ? <p className='reminder'>* Recuerda guardar los cambios para no perderlos.</p>
                : ''
            }
            <div className="buttons-line">
                <button id="guardar" onClick={onClickHandler}>Guardar cambios</button>
                <button id="expediente"  onClick={onClickHandler}>Volver al expediente</button>
            </div>
            
            <div className='content'>
                <form>
                    {/*<h3>¿De qué tipo de escenario se trata?</h3>
                    <select id="escenario" name="escenario"
                        value={expediente.escenario || ''}
                        onChange={onChangeExpedienteHandler}>
                        {escenarios.map((option, index) => (
                            <option key={index} value={index+1}>
                                {option}
                            </option>
                        ))}
                    </select>*/}
                    <h3>Datos de registro:</h3>
                    <label htmlFor="plataforma">Plataforma:</label>
                    <select id="plataforma" name="plataforma"
                        value={expediente.plataforma || ''}
                        onChange={onChangeExpedienteHandler}>
                        {plataformas.map((option, index) => (
                            <option key={index} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                    <br/>
                    <label htmlFor="responsable">Responsable de la plataforma / sistema de protección:</label>
                    <input type="text" id="responsable" name="responsable"
                        value={expediente.responsable || ''}/>
                    <label htmlFor="fecha_apertura">Fecha de apertura:</label>
                    <input type="date" readOnly id="fecha_apertura" name="fecha_apertura"
                        value={expediente.fecha_apertura || ''}
                        onChange={onChangeExpedienteHandler}/>
                    <label htmlFor="fecha_archivo">Fecha de archivo:</label>
                    <input type="date" id="fecha_archivo" name="fecha_archivo"
                        value={expediente.fecha_archivo || ''}
                        onChange={onChangeExpedienteHandler}/>
                    {/*}
                    <label htmlFor="fecha_cierre">Fecha de cierre:</label>
                    <input type="date" id="fecha_cierre" name="fecha_cierre"
                        value={expediente.fecha_cierre || ''}
                        onChange={onChangeExpedienteHandler}/>
                    */}

                    <h3>Registro de actuaciones:</h3>
                    <Actuaciones editable={true} />

                    <h3>Resultados:</h3>
                    <label htmlFor="medidas">Medidas adoptadas:</label>
                    <textarea id="medidas" name="medidas"
                        onChange={onChangeExpedienteHandler}>
                        {expediente.medidas || ''}
                    </textarea>
                    <label htmlFor="resultados">Resultados de las medidas:</label>
                    <textarea id="resultados" name="resultados"
                        onChange={onChangeExpedienteHandler}>
                        {expediente.resultados || ''}
                    </textarea>
                    <label htmlFor="motivo_cierre">Motivo de cierre del expediente:</label>
                    <textarea id="motivo_cierre" name="motivo_cierre"
                        onChange={onChangeExpedienteHandler}>
                        {expediente.motivo_cierre || ''}
                    </textarea>

                    <h3>Responsable del expediente:</h3>
                    <label htmlFor="nombre_responsable">Nombre:</label>
                    <input type="text" id="nombre_responsable" name="nombre_responsable"
                        value={expediente.nombre_responsable || 'Aurora Martín'}/>
                    <label htmlFor="funcion_responsable">Función dentro del SdPI:</label>
                    <input type="text" id="funcion_responsable" name="funcion_responsable"
                        value={expediente.funcion_responsable || 'Responsable del SdPI'}/>
                    <label htmlFor="fecha_firma">Fecha de firma:</label>
                    <input type="date" id="fecha_firma" name="fecha_firma"
                        value={expediente.fecha_firma || ''}
                        onChange={onChangeExpedienteHandler}/>
                    {/*<h3>Catalogación de datos:</h3>*/}
                </form>
            </div>
            <div className="buttons-line">
                <button id="guardar2" onClick={onClickHandler}>Guardar cambios</button>
                {
                    user && ('rol' in user) && user.rol.find((element) => element === 'admin')
                        ? <button id="cerrar" onClick={onClickHandler}>Guardar y cerrar expediente</button>
                        : ''
                }
            </div>
            <p className='reminder'>
                {status === STATUS.SAVED
                    ? 'Cambios guardados'
                    : '* Hay cambios sin guardar.'
                }
            </p>
        </>
    );
}